import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';

import makeSvgSingleLine from 'src/components/makeSvgSingleLine';
import svgDataURL from 'src/helpers/svgDataURL';
import { Assets, AssetTitle, ReasonItem } from './Cancellation.types';
import slugify from '../../helpers/slugify';

export const parseReasonList = (markdown: string): ReasonItem[] => {
  const tokens = marked.lexer(makeSvgSingleLine(markdown));
  const reasons: ReasonItem[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        reasons.push({
          id: slugify(token.text),
          title: token.text,
          svg: null,
        });
        break;
      }
      case 'paragraph': {
        reasons[reasons.length - 1].svg = svgDataURL(token.text);
        break;
      }
    }
  }

  return reasons;
};

const useCancellationAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo<Assets>((): Assets => {
    return {
      title: t(AssetTitle.Title),
      reason: {
        description: t(AssetTitle.ReasonDescription),
        list: parseReasonList(t(AssetTitle.ReasonList)),
      },
      summary: {
        cancelText: t(AssetTitle.CancelText),
      },
    };
  }, [ready]);
};

export default useCancellationAssets;
