// Defined in contentful https://app.contentful.com/spaces/w61n0jg6cdlz/entries/jRUhhy4FoyalU1N8VQ4mR
export type ReasonItem = {
  id: string;
  svg: string;
  title: string;
};

type Reason = {
  description: string;
  list: ReasonItem[];
};

type Summary = {
  cancelText: string;
};

export type Assets = {
  title: string;
  reason: Reason;
  summary: Summary;
};

export enum AssetTitle {
  ReasonDescription = 'cancel-membership.reason.description',
  ReasonList = 'cancel-membership.reason.list',
  CancelText = 'cancel-membership.text',
  Title = 'cancel-membership.title',
}
