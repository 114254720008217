import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import dateFormat from 'dateformat';
import { Button } from '@hagerty/react-components';

import styles from './CancellationSummary.module.scss';
import useCancellationAssets from '../useCancellationAssets';
import { Translate } from 'src/components/Translate';
import useDateFormat from 'src/hooks/useDateFormat';
import useCancelMembership from 'src/api/useCancelMembership';
import useMembership from 'src/api/useMembership';
import usePreviewCancelMembership from 'src/api/usePreviewCancelMembership';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import Breadcrumbs from 'src/components/Breadcrumbs';
import LoadingContainer from 'src/components/LoadingContainer';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Split, Treatment } from 'src/constants/Split';

type CancelDetails = {
  refundType: string;
  amount: number;
  refundText: string;
  expirationText: string;
};

type Props = {
  userCancelReason: string;
  onBackClick: () => void;
};

export const CancellationSummary: React.FC<Props> = ({ userCancelReason, onBackClick }) => {
  const { isShelbyCustomer } = useShelbyCustomer();
  const { data: membership, secondMembershipQueryForLog, ...membershipQuery } = useMembership();
  const [callOnce, setCallOnce] = useState(false);
  const previewCancelMembership = usePreviewCancelMembership(membership?.data || {});
  const cancelMembership = useCancelMembership(membership?.data || {}, userCancelReason || null);
  const [cancelDetails, setCancelDetails] = useState<CancelDetails>({} as CancelDetails);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { trackView, trackInteraction } = useTrackingContext();
  const format = useDateFormat();
  const treatments = useTreatments([Split.FeaturePreventDuplicateMemberships]);
  const hasMemberGraphTreatment =
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.MemberGraph ||
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.BothMemberGraph;

  const assets = useCancellationAssets();
  const { summary, title } = assets;

  useTracking('hdc_cancel_confirmation', null, true);

  const i18n = {
    expiresLater: 'cancel-membership.summary.expires.later',
    refund: 'cancel-membership.summary.refund',
    bannerCancel: 'cancelPlan.banner.cancel',
    bannerCancelLater: 'cancelPlan.banner.cancel.later',
  };

  useEffect(() => {
    if (membership?.data?.id && !isSubmitting) {
      previewCancelMembership().then(({ data }) => {
        const details = data.previewCancelMembership.data;

        if (details.refundType === 'partial' || details.refundType === 'full') {
          details.refundText = i18n.refund;
        } else if (details.refundType === 'none') {
          details.expirationText = i18n.expiresLater;
        }
        setCancelDetails(details);
      });
    }
    if (isSubmitting && (membership?.hasMembership() === false || membership.willBeCanceled())) {
      membershipQuery?.stopPolling();
      // For membership log
      secondMembershipQueryForLog?.stopPolling();
      // set timeout for state to still be sent when using member graph
      setTimeout(() => {
        navigate('/my-account/', {
          state: {
            msg: membership?.data?.subscription?.status === 'canceled' ? i18n.bannerCancelLater : i18n.bannerCancel,
            date: format(membership.willBeCanceled() ? membership.expireAt : new Date()),
            cancelledWithMemberGraph: hasMemberGraphTreatment,
          },
        });
      });
    }
  }, [membership?.data]);

  useEffect(() => {
    if (trackView && membership?.data && !callOnce) {
      trackView('hdc_cancel_confirmation', null, {
        product_code: membership.data.name,
        commerce_product: [membership.data.name],
      });

      setCallOnce(true);
    }
  }, [trackView, membership]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await cancelMembership();
      membershipQuery?.startPolling(200);
      // For membership log
      secondMembershipQueryForLog?.startPolling(200);

      trackInteraction(
        'click',
        'cancel_membership',
        {
          product_code: membership.data.name,
          commerce_product: [membership.data.name],
          hdc_status: 'inactive',
        },
        'hdc_membership_cancelled'
      );
    } catch (error) {
      console.error('Cancel membership failed', error);
    }
  };

  const handleBackClick = () => {
    trackInteraction('link', 'Back');
    onBackClick && onBackClick();
  };

  const expiresAt = membership?.data ? dateFormat(membership?.expireAt, 'mmm d, yyyy') : '';
  const refund = typeof cancelDetails.amount === 'number' && (cancelDetails.amount / 100).toFixed(2);
  const isReady = !membershipQuery.loading && (cancelDetails.refundText || cancelDetails.expirationText);

  return (
    <div className={`${styles.cancellationSummary} container`} data-cy="cancellation-summary">
      <div className={`${styles.cancellationSummary__container} max-width-container `}>
        <h1 className="font-size-title no-padding">{title}</h1>
        {!isReady || isSubmitting ? (
          <LoadingContainer height={158} />
        ) : (
          <p className={styles.cancellationSummary__description} data-cy="cancellation-summary-description">
            {cancelDetails.refundText && (
              <Translate resourceKey={cancelDetails.refundText} values={{ refund }} useHtml />
            )}
            {cancelDetails.expirationText && (
              <Translate resourceKey={cancelDetails.expirationText} values={{ expiresAt }} useHtml />
            )}
          </p>
        )}
        <div className={styles.cancellationSummary__buttonContainer}>
          <Breadcrumbs
            originTransKey={'breadcrumbs.back'}
            callbackFn={handleBackClick}
            dataCy="cancellation-summary-back"
            className={styles.cancellationSummary__backButton}
          />
          <Button
            className="button button__critical"
            onClick={handleSubmit}
            disabled={!isReady || isSubmitting}
            testId="cancellation-summary-submit"
          >
            {summary.cancelText}
            <svg className={`${styles.cancellationSummary__criticalArrow} icon-small`} aria-hidden="true">
              <use xlinkHref="#12-chevron-right" />
            </svg>
          </Button>
        </div>
        <div className="inset-l"></div>
        {isShelbyCustomer && <StateFarmLogo />}
      </div>
    </div>
  );
};
