import React, { useState, useEffect } from 'react';

import styles from './CancellationReason.module.scss';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { CardButton } from 'src/components/CardButton';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import useMembership from 'src/api/useMembership';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

import { CancellationOtherForm } from '../CancellationOtherForm';
import useCancellationAssets from '../useCancellationAssets';
import { ReasonItem } from '../Cancellation.types';

type Props = {
  onClick: (reason: ReasonItem['title']) => void;
};

export const CancellationReason: React.FC<Props> = ({ onClick }) => {
  const [callOnce, setCallOnce] = useState(false);
  const [showOtherReasonForm, setShowOtherReasonForm] = useState(false);
  const { isShelbyCustomer } = useShelbyCustomer();
  const { data: membership } = useMembership();
  const assets = useCancellationAssets();
  const { trackView, trackInteraction } = useTrackingContext();
  const { reason, title } = assets;
  const otherReasonTitle = 'Other: please specify';

  useTracking('hdc_cancellation_details', null, true);

  useEffect(() => {
    if (trackView && membership?.data && !callOnce) {
      trackView('hdc_cancellation_details', null, {
        product_code: membership.data.name,
        commerce_product: [membership.data.name],
      });

      setCallOnce(true);
    }
  }, [trackView, membership]);

  const reasonClick = (reasonTitle: ReasonItem['title']) => {
    if (reasonTitle == otherReasonTitle) {
      setShowOtherReasonForm(true);
    } else {
      onClick(reasonTitle);
    }

    trackInteraction(
      'click',
      reasonTitle,
      {
        product_code: membership?.data.name,
        commerce_product: [membership?.data.name],
        cancellation_reason: reasonTitle,
      },
      'hdc_cancellation_reason'
    );
  };

  return (
    <div className={`${styles.cancellationReason} container`} data-cy="cancellation-reasons">
      <div className={`${styles.cancellationReason__container} max-width-container`}>
        <h1 className="font-size-title no-padding">{title}</h1>
        <p className={styles.cancellationReason__description}>{reason.description}</p>
        {reason.list &&
          reason.list.map((reason) => {
            if (reason.title != otherReasonTitle || (!showOtherReasonForm && reason.title == otherReasonTitle))
              return (
                <CardButton
                  title={reason.title}
                  svg={reason.svg}
                  onClick={reasonClick}
                  key={`reason-${reason.title}`}
                  id={reason.id}
                />
              );
          })}
        {showOtherReasonForm && <CancellationOtherForm onClickSubmit={onClick} />}
        <div className="inset-l"></div>
        {isShelbyCustomer && <StateFarmLogo />}
      </div>
    </div>
  );
};
