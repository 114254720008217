import React from 'react';

import { Translate } from 'src/components/Translate';
import styles from './CloseHeader.module.scss';
import { CloseHeaderProps } from './CloseHeader.types';

export const CloseHeader: React.FC<CloseHeaderProps> = ({ resourceKey, onClick }) => {
  return (
    <div className={styles.closeHeader}>
      <div className="container">
        <div className={styles.closeHeader__container}>
          <Translate resourceKey={resourceKey} />
          <button className={styles.closeHeader__button} onClick={onClick} data-cy="close-header-button">
            <svg className={`${styles.closeHeader__icon} icon`} aria-label="Close">
              <use xlinkHref="#24-close-x" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
