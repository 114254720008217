import React, { useEffect } from 'react';
import { PageProps, navigate } from 'gatsby';
import { useTreatments } from '@splitsoftware/splitio-react';
import { Helmet } from 'react-helmet';

import { useAuthContext } from 'src/components/AuthProvider';
import useMembership from 'src/api/useMembership';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { isReady } from 'src/components/SplitContext/isReady';
import { Split, Treatment } from 'src/constants/Split';
import { Path } from 'src/constants/Route';
import { Cancellation } from 'src/components/Cancellation';

const metadata = {
  title: 'Membership Cancellation | Hagerty Drivers Club',
  description: 'Cancel your Hagerty Drivers Club membership.',
};

const CancellationPage: React.FC<PageProps> = ({ location }) => {
  usePreventAgentAccess();
  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const { data: membership } = useMembership();
  const treatments = useTreatments([Split.FeatureCancellationReasons]);
  const hasCancellationReasons = treatments[Split.FeatureCancellationReasons].treatment === Treatment.On;
  const splitIsReady = isReady();
  const { state = {} } = location;
  const hasStateNav = state?.['nav'];

  useEffect(() => {
    const unauthenticated = !loadingUser && !isAuthenticated;
    const hasMembership = membership?.hasMembership();
    const shouldRedirect =
      !hasCancellationReasons ||
      !hasStateNav ||
      hasMembership === false ||
      membership?.willBeCanceled() ||
      unauthenticated;

    if (splitIsReady && shouldRedirect) {
      navigate(Path.MyAccount);
    }
  }, [splitIsReady, loadingUser, isAuthenticated, membership]);

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
      </Helmet>
      <Cancellation />
    </>
  );
};

export default CancellationPage;
