import React from 'react';
import { Button, TextInput } from '@hagerty/react-components';
import { Controller, useForm } from 'react-hook-form';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';
import { ReasonItem } from '../Cancellation.types';

type Props = {
  onClickSubmit: (reason: ReasonItem['title']) => void;
};

type FormData = {
  reason: string;
};

const CancellationOtherForm = ({ onClickSubmit }: Props) => {
  const { t } = useTranslation();
  const defaultValues = { reason: '' };
  const {
    errors,
    control,
    formState: { isSubmitting },
    watch,
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues });

  const [flags] = useLocalStorage('@FLAGS', {});
  if (flags['DEBUG']) {
    console.log(defaultValues, watch());
  }

  const onSubmit = async (data: FormData) => {
    onClickSubmit(`Other Reason: ${data.reason}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="reason"
        control={control}
        rules={{
          required: true,
          validate: (reason: string) => reason.length <= 500,
        }}
        render={({ value, onChange }) => (
          <>
            <TextInput
              inputLabel="Please specify"
              hasFormElementWrapper
              name="reason"
              value={value}
              onChange={onChange}
              status={errors['reason'] ? 'Error' : 'Default'}
              hintText={
                errors['reason'] ? (!value ? t('form.validation.requiredField') : 'Maximum 500 characters') : ''
              }
              placeholder="Other reason"
            />
            <div className="inset-m"></div>
            <Button
              buttonType="Primary"
              buttonSize="Large"
              type="submit"
              testId="submit-other-cancellation-reason"
              disabled={!value || isSubmitting || !!errors['reason']}
            >
              Submit
            </Button>
          </>
        )}
      />
    </form>
  );
};

export { CancellationOtherForm };
