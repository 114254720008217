import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { Path } from 'src/constants/Route';
import { CloseHeader } from 'src/components/CloseHeader';
import { CancellationReason } from './CancellationReason';
import { CancellationSummary } from './CancellationSummary';
import { ReasonItem } from './Cancellation.types';

export const Cancellation: React.FC = () => {
  const [reasonSelected, setReasonSelected] = useState(null);
  const [showReason, setShowReason] = useState(true);
  const [showSummary, setShowSummary] = useState(false);

  const handleCloseClick = () => {
    navigate(Path.MyAccount);
  };

  const handleReasonClick = (reason: ReasonItem['title']) => {
    setReasonSelected(reason);
    setShowReason(false);
    setShowSummary(true);
  };

  const handleBackClick = () => {
    setShowSummary(false);
    setShowReason(true);
  };

  return (
    <div className="color-background-light page-container-padding">
      <CloseHeader resourceKey="cancel-membership.header.text" onClick={handleCloseClick} />
      {showReason && <CancellationReason onClick={handleReasonClick} />}
      {showSummary && <CancellationSummary onBackClick={handleBackClick} userCancelReason={reasonSelected} />}
    </div>
  );
};
