import React from 'react';
import styles from './CardButton.module.scss';

type Props = {
  id?: string;
  title: string;
  svg?: string;
  onClick: (title: string) => void;
};

export const CardButton: React.FC<Props> = ({ id, title, svg, onClick }) => {
  const handleClick = () => {
    onClick && onClick(title);
  };

  return (
    <button className={styles.cardButton} onClick={handleClick} data-cy="card-button" id={id}>
      <span className={styles.cardButton__container}>
        {svg && <img className={`${styles.cardButton__icon} icon`} src={svg} alt="icon" />}
        <p className={styles.cardButton__title}>{title}</p>
      </span>
      <svg viewBox="0 0 7 16" className={`${styles.cardButton__chevron} icon`} stroke="currentColor">
        <path fill="none" d="M1 1L6 8L1 15" strokeWidth="1.5" strokeMiterlimit="10" />
      </svg>
    </button>
  );
};
